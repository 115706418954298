import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useLogsList() {
  // Use toast
  const toast = useToast();

  const refLogsListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "description"},
    { key: "date"},
  ];
  const per_page = ref(20);
  const totalLogs = ref(0);
  const current_page = ref(1);
  const per_pageOptions = [20, 40, 75, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refLogsListTable.value
      ? refLogsListTable.value.localItems.length
      : 0;
    return {
      from:
        per_page.value * (current_page.value - 1) + (localItemsCount ? 1 : 0),
      to: per_page.value * (current_page.value - 1) + localItemsCount,
      of: totalLogs.value,
    };
  });

  const refetchData = () => {
    refLogsListTable.value.refresh();
  };

  watch([current_page, per_page, searchQuery], () => {
    refetchData();
  });

  const fetchLogs = (ctx, callback) => {
    store
      .dispatch("app-logs/fetchLogs", {
        q: searchQuery.value,
        per_page: per_page.value,
        page: current_page.value,
      })
      .then((response) => {
        const { data: logs, total } = response.data;

        callback(logs);
        totalLogs.value = total;
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Logs list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchLogs,
    tableColumns,
    per_page,
    current_page,
    totalLogs,
    dataMeta,
    per_pageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refLogsListTable,
    refetchData,
  };
}
