<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="per_page"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="per_pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refLogsListTable"
        class="position-relative"
        :items="fetchLogs"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Author -->
        <template #cell(date)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              moment(data.item.created_at).fromNow()
            }}</span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="current_page"
              :total-rows="totalLogs"
              :per-page="per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useLogsList from "./useLogsList";
import logsStore from "./logsStore";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,

    vSelect,
  },
  setup() {
    const LOGS_APP_STORE_MODULE_NAME = "app-logs";

    // Register module
    if (!store.hasModule(LOGS_APP_STORE_MODULE_NAME))
      store.registerModule(LOGS_APP_STORE_MODULE_NAME, logsStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LOGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(LOGS_APP_STORE_MODULE_NAME);
    });

    const isAddNewLogsSidebarActive = ref(false);

    const {
      fetchLogs,
      tableColumns,
      per_page,
      current_page,
      totalLogs,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLogsListTable,
      refetchData,
    } = useLogsList();

    return {
      // Sidebar
      isAddNewLogsSidebarActive,

      fetchLogs,
      tableColumns,
      per_page,
      current_page,
      totalLogs,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLogsListTable,
      refetchData,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
